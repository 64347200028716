import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const InteriorPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  const titleAsClass = title
    .toLowerCase()
    .split(" ")
    .join("-")
    .split("’")
    .join("")
    .split("?")
    .join("");

  return (
    <section id="interior-page" className={`section ${titleAsClass}`}>
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-1 has-text-weight-bold is-bold-light">
              {title}
            </h1>
          </div>
        </div>
      </div>
      <div className="content-body container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <PageContent className="content" content={content} />
          </div>
        </div>
      </div>
    </section>
  );
};

InteriorPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const InteriorPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Helmet title={`${post.frontmatter.title} | Amos Institute`}>
        <meta name="description" content={post.frontmatter.description} />
        <meta
          property="og:title"
          content={`${post.frontmatter.title} | Amos Institute`}
        />
        <meta
          property="og:description"
          content={post.frontmatter.description}
        />
      </Helmet>
      <InteriorPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

InteriorPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InteriorPage;

export const InteriorPageQuery = graphql`
  query InteriorPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
